// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { ParticipantDto } from '../_module/dto/participant.dto';
import { CurrentChangeDto } from '../event/dto/current-change.dto';
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class PlayerModel {
  static start(
    eventId: string,
    action: 'RESTART' | 'CONTINUE' | null = null,
  ): Promise<{ eventSessionId: number }> {
    return wsc.execute('/ws/player/start', eventId, action) as any;
  }

  static end(eventId: string): Promise<void> {
    return wsc.execute('/ws/player/end', eventId) as any;
  }

  static interrupt(eventId: string): Promise<void> {
    return wsc.execute('/ws/player/interrupt', eventId) as any;
  }

  static setEventDisconnect(): Promise<void> {
    return wsc.execute('/ws/player/setEventDisconnect') as any;
  }

  static join(
    eventId: string,
    avatar: { name: string; picture: string },
  ): Promise<string> {
    return wsc.execute('/ws/player/join', eventId, avatar) as any;
  }

  static fetchCurrent(eventId: string): Promise<CurrentChangeDto> {
    return wsc.execute('/ws/player/fetchCurrent', eventId) as any;
  }

  static listenCurrentChanged(eventId: string): Observable<CurrentChangeDto> {
    return wsc.subscribe('/ws/player/listenCurrentChanged', eventId) as any;
  }

  static listenSessionExecAccountId(eventId: string): Observable<number> {
    return wsc.subscribe(
      '/ws/player/listenSessionExecAccountId',
      eventId,
    ) as any;
  }

  static updateCurrent(
    eventId: string,
    filmstripIndex: number,
    resetExecuted: boolean = false,
  ): Promise<any> {
    return wsc.execute(
      '/ws/player/updateCurrent',
      eventId,
      filmstripIndex,
      resetExecuted,
    ) as any;
  }

  static fetchParticipants(eventId: string): Promise<ParticipantDto[]> {
    return wsc.execute('/ws/player/fetchParticipants', eventId) as any;
  }

  static fetchNotWinners(eventId: string): Promise<ParticipantDto[]> {
    return wsc.execute('/ws/player/fetchNotWinners', eventId) as any;
  }

  static listenJoin(eventId: string): Observable<ParticipantDto> {
    return wsc.subscribe('/ws/player/listenJoin', eventId) as any;
  }

  static preview(eventId: string): Promise<any> {
    return wsc.execute('/ws/player/preview', eventId) as any;
  }

  static isJoined(eventId: string): Promise<boolean> {
    return wsc.execute('/ws/player/isJoined', eventId) as any;
  }

  static isValidJoined(eventId: string): Promise<boolean> {
    return wsc.execute('/ws/player/isValidJoined', eventId) as any;
  }
}
// 1de519956a748bd478663b68628b51a02fe399cb7c4d4dc5bd0e4c9fb165d483
