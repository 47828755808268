// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { AuthorizationOrderDto } from './dto/authorization-info.dto';
import { AuthorizationOrderMultiDto } from './dto/authorization-multi-info.dto';
import { AuthorizationOrderUpgradeDto } from './dto/authorization-upgrade-info.dto';
import { ContractDto } from './dto/contract.dto';
import { AuthorizationFetchPlanDto } from './dto/fetch-plan.dto';
import { OrderInfoList } from './dto/order-list.dto';
import { OrderInfo } from './dto/order.dto';
import { QuantityFeatureDto } from './dto/quantity-feature.dto';
import { SwitchFeatureDto } from './dto/switch-feature.dto';

export class AuthorizationModel {
  static fetchPlanList(): Promise<{
    data: AuthorizationFetchPlanDto[];
    total: number;
  }> {
    return wsc.execute('/ws/authorization/fetchPlanList') as any;
  }

  static fetchContracts(
    page: any = 1,
    pageSize: any = 30,
    order: 'ASC' | 'DESC' = 'ASC',
    year?: number,
  ): Promise<{ data: ContractDto[]; total: number }> {
    return wsc.execute(
      '/ws/authorization/fetchContracts',
      page,
      pageSize,
      order,
      year,
    ) as any;
  }

  static createOrder(order: AuthorizationOrderDto): Promise<any> {
    return wsc.execute('/ws/authorization/createOrder', order) as any;
  }

  static createOrders(order: AuthorizationOrderMultiDto): Promise<any> {
    return wsc.execute('/ws/authorization/createOrders', order) as any;
  }

  static upgradeOrder(order: AuthorizationOrderUpgradeDto): Promise<any> {
    return wsc.execute('/ws/authorization/upgradeOrder', order) as any;
  }

  static fetchManagerLimit(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchManagerLimit') as any;
  }

  static fetchPlayerVerify(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchPlayerVerify') as any;
  }

  static fetchOrderInfoById(orderId: string): Promise<OrderInfo> {
    return wsc.execute('/ws/authorization/fetchOrderInfoById', orderId) as any;
  }

  static fetchOrderInfo(
    page?: number,
    pageSize?: number,
    startDate?: string,
    endDate?: string,
  ): Promise<OrderInfoList> {
    return wsc.execute(
      '/ws/authorization/fetchOrderInfo',
      page,
      pageSize,
      startDate,
      endDate,
    ) as any;
  }

  static fetchFeatures(): Promise<{
    // 同時開啟活動數
    ACTIVE: QuantityFeatureDto;
    // 活動人數
    FRIEND: QuantityFeatureDto;
    // 管理員人數
    MANAGER: QuantityFeatureDto;
    // 玩家如何參與活動 > 輸入指定資料
    PLAYER_VERIFY: SwitchFeatureDto;
    // 支援未中獎者參加
    BETA_PARTICIPANT_RULE: SwitchFeatureDto;
  }> {
    return wsc.execute('/ws/authorization/fetchFeatures') as any;
  }
}
// d8d38940554f1a0d235a4d8d8dc251a400951c18a178940220fde69a6dc394e8
